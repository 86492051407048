import Logo from './logo.png';
import Banner from './banner.png';
import { Config } from '../../interfaces/config.interface';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  logo_width: '250px',
  name: 'Fundación Grupo México',
  login_message: 'VOLUNTARIADO CORPORATIVO',
  bannerODS: Banner,
  location_lat: 19.4000932,
  location_lng: -99.115311,
  location_zoom: 4,
  idiom: 'es-MX',
};

export default config;
